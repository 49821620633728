import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'gestao-escolas',
    data: {
      breadcrumb: 'Gestão de escolas',
      filter: false
    },
    loadChildren: () =>
      import('./school-management/school-management.module').then(
        (m) => m.SchoolManagementModule
      )
  },
  // {
  //   path: 'importacao',
  //   data: {
  //     breadcrumb: 'Importação',
  //     filter: false
  //   },
  //   loadChildren: () =>
  //     import('./imports/imports.module').then((m) => m.ImportsModule)
  // },
  {
    path: 'gestao-usuarios',
    loadChildren: () =>
      import('../users-management/users-management.module').then(
        (m) => m.UsersManagementModule
      )
  },
  {
    path: 'relatorios',
    loadChildren: () =>
      import('../reports/reports.module').then((m) => m.ReportsModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserSettingsRoutingModule {}
