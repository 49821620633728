import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';
import {
  featherEdit,
  featherEdit3,
  featherLogOut,
  featherPieChart,
  featherUpload,
  featherVideo
} from '@ng-icons/feather-icons';
import { CommonsModule } from 'src/app/core/commons';
import { SharedModule } from 'src/app/shared/shared.module';
import { UiModule } from 'src/app/ui/ui.module';

import { HasPermissionPipe } from './has-permission.pipe';
import { UserSettingsRoutingModule } from './user-settings-routing.module';
import { UserSettingsComponent } from './user-settings.component';

@NgModule({
  declarations: [UserSettingsComponent, HasPermissionPipe],
  imports: [
    CommonModule,
    UserSettingsRoutingModule,
    SharedModule,
    NgIconsModule.withIcons({
      featherEdit,
      featherEdit3,
      featherUpload,
      featherLogOut,
      featherVideo,
      featherPieChart
    }),
    CommonsModule,
    UiModule
  ],
  exports: [UserSettingsComponent]
})
export class UserSettingsModule {}
