import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Subject, from, fromEvent } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { PermissionService } from 'src/app/core/services/permission.service';
import { EAccessProfile } from 'src/app/shared/enums/access-profile.enum';
import { AuthStore } from 'src/app/shared/store/auth.store';

interface MenuSetting {
  icon: string;
  name: string;
  link: string;
  permissions: EAccessProfile[];
  isNew?: boolean;
  isHidden?: boolean;
}

@Component({
  selector: 'liv-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements AfterViewInit, OnDestroy {
  @ViewChildren('settingsItem', { read: ElementRef })
  settingsItem: QueryList<ElementRef<HTMLAnchorElement>>;

  private destroy$ = new Subject<boolean>();

  username: string;

  expandSettingsDropdown = false;
  isSpaceToBeUser = false;
  menuSettings: MenuSetting[] = [
    /*{
      icon: 'featherUpload',
      name: 'Importação de usuários',
      link: '/configuracoes-usuario/importacao',
      permissions: [EAccessProfile.administrator]
    },*/
    {
      icon: 'featherEdit',
      name: 'Gestão de escolas',
      link: '/configuracoes-usuario/gestao-escolas',
      permissions: [
        EAccessProfile.it,
        EAccessProfile.administrator,
        EAccessProfile.adviser
      ]
    },
    {
      icon: 'featherEdit3',
      name: 'Gestão de usuários',
      link: '/configuracoes-usuario/gestao-usuarios',
      permissions: [
        EAccessProfile.it,
        EAccessProfile.administrator,
        EAccessProfile.adviser
      ],
      isNew: true
    },
    {
      icon: 'featherPieChart',
      name: 'Relatórios',
      link: '/configuracoes-usuario/relatorios',
      permissions: [
        EAccessProfile.it,
        EAccessProfile.administrator,
        EAccessProfile.adviser
      ],
      isNew: true
    },
    {
      icon: 'featherVideo',
      name: 'Live stream',
      link: '/live-stream',
      permissions: [
        EAccessProfile.it,
        EAccessProfile.administrator,
        EAccessProfile.adviser
      ]
    }
  ];

  constructor(
    private authStore: AuthStore,
    private permissionService: PermissionService
  ) {
    this.username = this.authStore.authSnapshot.nome;
    this.isSpaceToBeUser = this.permissionService.isSpaceToBe;
  }

  ngAfterViewInit(): void {
    from(Array.from(this.settingsItem))
      .pipe(
        takeUntil(this.destroy$),
        mergeMap((settingItem) => fromEvent(settingItem.nativeElement, 'click'))
      )
      .subscribe(() => (this.expandSettingsDropdown = false));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  handleToggleProfile(): void {
    this.expandSettingsDropdown = !this.expandSettingsDropdown;
  }

  onLogout(): void {
    this.authStore.logout();
  }

  onClickOuside(): void {
    this.expandSettingsDropdown = false;
  }
}
