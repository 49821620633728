<div class="user-settings-container">
  <div
    role="button"
    aria-label="Open user settings"
    (click)="handleToggleProfile()"
  >
    <liv-avatar size="md" [name]="username"></liv-avatar>
  </div>

  <div
    class="user-settings-container__dropdown"
    [ngClass]="{
      'user-settings-container__dropdown--active': expandSettingsDropdown
    }"
  >
    <div class="user-settings-container__innerDropdown">
      <ng-container *ngIf="!isSpaceToBeUser">
        <ng-container *ngFor="let item of menuSettings">
          <ng-container
            *ngIf="(item.permissions | hasPermission) && !item.isHidden"
          >
            <a
              [routerLink]="item.link"
              #settingsItem
              class="user-settings-container__dropdown__item"
            >
              <ng-icon [name]="item.icon" size="16" class="!mr-2"></ng-icon>
              {{ item.name }}

              <span
                *ngIf="item.isNew"
                class="!ml-2 flex h-5 w-fit items-center justify-center !rounded-lg !border border-feedback-success-medium bg-feedback-success-pure/10 px-2 py-1 text-xs font-medium text-feedback-success-medium"
              >
                Novo
              </span>
            </a>
          </ng-container>
        </ng-container>
      </ng-container>

      <button
        class="user-settings-container__dropdown__item user-settings-container__dropdown__item--logout"
        (click)="onLogout()"
      >
        <ng-icon name="featherLogOut" size="16" class="!mr-2"></ng-icon>
        Sair
      </button>
    </div>
  </div>
</div>

<div
  class="overlay-panel"
  overlay
  (onClickOuside)="onClickOuside()"
  [activeOverlay]="expandSettingsDropdown"
></div>
