import { Pipe, PipeTransform } from '@angular/core';
import { PermissionService } from 'src/app/core/services/permission.service';

import { EAccessProfile } from '../../shared/enums/access-profile.enum';

@Pipe({
  name: 'hasPermission'
})
export class HasPermissionPipe implements PipeTransform {
  constructor(private permissionService: PermissionService) {}

  transform(value: EAccessProfile[]) {
    return this.permissionService.hasPermission(value);
  }
}
